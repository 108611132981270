import React from 'react'
import { Link } from 'gatsby'
import ButtonBase from '@material-ui/core/ButtonBase'
import ArrowRight from '@material-ui/icons/ArrowForwardIos'

import { s, colors, globals, alpha } from '../style'

export const ButtonMain = ({
  theme,
  children,
  language,
  link,
  click,
  action,
  button,
  uppercase,
  reversed,
  left,
  center,
  right,
  extraCss,
  sOuter,
  extraButtonBase,
}) => {
  const url =
    // (language ? `/${language}` : '') +
    (button?.link_page ? `/${button?.link_page}` : '') +
    (button?.link_section ? `#${button?.link_section}` : '')

  return (
    <div
      onClick={() => click}
      onKeyPress={() => click}
      role="button"
      tabIndex={0}
      css={[
        sButtonContainer,
        left && { justifyContent: 'flex-start' },
        center && { justifyContent: 'center' },
        right && { justifyContent: 'flex-end' },
        sOuter,
      ]}>
      <ButtonBase css={[sButtonBase, extraButtonBase]}>
        {button ? (
          <Link
            to={url}
            css={[
              sButton,
              theme && sButtonTheme(theme),
              theme && reversed && sButtonThemeReversed(theme),
              (uppercase || button?.uppercase) && {
                textTransform: 'uppercase',
              },
              extraCss,
            ]}>
            {children}
          </Link>
        ) : link ? (
          <a
            href={link}
            rel="noreferrer"
            target="_blank"
            css={[
              sButton,
              theme && sButtonTheme(theme),
              theme && reversed && sButtonThemeReversed(theme),
              (uppercase || button?.uppercase) && {
                textTransform: 'uppercase',
              },
              extraCss,
            ]}>
            {children}
          </a>
        ) : (
          <div
            onClick={() => action}
            onKeyPress={() => action}
            css={[
              sButton,
              theme && sButtonTheme(theme),
              theme && reversed && sButtonThemeReversed(theme),
              (uppercase || button?.uppercase) && {
                textTransform: 'uppercase',
              },
              extraCss,
            ]}>
            {children}
          </div>
        )}
      </ButtonBase>
    </div>
  )
}

const sButtonContainer = {
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  [s.xs]: {
    button: {
      width: '100%',
    },
  },
}

const sButtonBase = {
  height: 'max-content',
  span: {
    borderRadius: globals.roundness.buttons,
  },
  fontFamily: 'font1',
  transition:
    'transform 0.25s ease-in-out, box-shadow 0.25s ease-in-out, filter 0.25s ease-in-out, color 0.25s ease-in-out, background-color 0.25s ease-in-out, opacity 0.25s ease-in-out',
  '&:hover': {
    [s.hover]: {
      transform: 'translateY(-8px)',
      filter: 'brightness(150%)',
    },
  },
}

const sButton = {
  transition: 'inherit',
  padding: '0.875rem 2rem',
  fontSize: '1rem',
  fontWeight: 700,
  [s.sm_down]: {
    width: '100%',
    borderRadius: globals.roundness.buttons,
  },
  [s.md]: {
    borderRadius: globals.roundness.buttons,
  },
  '&:hover': {
    boxShadow: `0px 8px 24px ${colors.black.concat(alpha[30])}`,
  },
}

const sButtonTheme = (theme) => ({
  background: `linear-gradient(${colors[theme].buttons.initial.angle}deg, ${colors[theme].buttons.initial.background[0]}, ${colors[theme].buttons.initial.background[1]})`,
  color: colors[theme].buttons.initial.text,
})

const sButtonThemeReversed = (theme) => ({
  background: `linear-gradient(${colors[theme].buttons.reversed.angle}deg, ${colors[theme].buttons.initial.background[0]}, ${colors[theme].buttons.reversed.background[1]})`,
  color: colors[theme].buttons.reversed.text,
})
export const ButtonGradient = ({
  button,
  children,
  language,
  align,
  extraCss,
}) => {
  const url =
    // (language ? `/${language}` : '') +
    (button?.link_page ? `/${button?.link_page}` : '') +
    (button?.link_section ? `#${button?.link_section}` : '')

  // console.log(button)

  return (
    <div
      css={[
        sButtonContainer,
        align === 'left' && { justifyContent: 'flex-start' },
        align === 'center' && { justifyContent: 'center' },
        align === 'right' && { justifyContent: 'flex-end' },
      ]}>
      <ButtonBase css={sButtonBase}>
        <Link
          to={url}
          css={[
            sButton,
            { color: button.textColor },
            button.gradient && button.gradient.colors.length > 1
              ? {
                  background: `linear-gradient(${
                    button.gradient?.angle !== null &&
                    `${button.gradient.angle}deg,`
                  }${button.gradient?.colors.map((color, id) =>
                    id < button.gradient?.colors?.length - 1
                      ? color?.color && `${color.color}`
                      : color.color
                  )})`,
                }
              : { backgroundColor: button.gradient.colors[0].color },
          ]}>
          {children}
        </Link>
      </ButtonBase>
    </div>
  )
}

export const ButtonText = ({
  children,
  theme,
  language,
  button,
  uppercase,
  extraCss,
  href,
  o,
}) => {
  const url =
    // (language ? `/${language}` : '') +
    (button?.link_page ? `/${button?.link_page}` : '') +
    (button?.link_section ? `#${button?.link_section}` : '')

  if (button)
    return (
      <Link
        to={url}
        css={[
          sButtonText(theme),
          (uppercase || button?.uppercase) && {
            textTransform: 'uppercase',
          },
          o && { opacity: o },
          extraCss,
        ]}>
        {children}
        <ArrowRight />
      </Link>
    )

  return (
    <a
      href={href}
      traget="_blank"
      rel="noreferrer"
      css={[sButtonText(theme), extraCss]}>
      {children}
      <ArrowRight />
    </a>
  )
}

const sButtonText = (theme) => ({
  backgroundColor: colors[theme].main.concat(alpha[0]),
  transition:
    'color 0.25s ease-out, background-color 0.25s ease-out, filter 0.25s ease-out, opacity 0.25s ease-out',
  display: 'flex',
  alignItems: 'center',
  padding: '0.5rem 1rem',
  borderRadius: '2rem',
  opacity: 0.8,
  'span:first-of-type': {
    fontWeight: 300,
  },
  'span:last-of-type': {
    margin: '0 0.25rem',
    fontWeight: 700,
  },
  svg: {
    width: '1rem',
  },
  '&:hover': {
    [s.hover]: {
      opacity: 1,
      color: colors[theme].main,
      backgroundColor: colors[theme].main.concat(alpha[16]),
      filter: 'brightness(120%)',
    },
  },
})

export const ButtonReactive = ({
  children,
  extraCss,
  theme,
  active,
  left,
  center,
  right,
  onClickFunction,
}) => {
  return (
    <div
      css={[
        sButtonContainer,
        left && { justifyContent: 'flex-start' },
        center && { justifyContent: 'center' },
        right && { justifyContent: 'flex-end' },
        extraCss,
      ]}
      onClick={onClickFunction}
      onKeyPress={onClickFunction}
      role="button"
      tabIndex={0}>
      <ButtonBase css={sButtonBase} id="form_a">
        <div
          css={[
            sButton,
            theme && sButtonTheme(theme),
            { textTransform: 'uppercase' },
            active ? sButtonReactive.active : sButtonReactive.inactive,
            extraCss,
          ]}>
          {children}
        </div>
      </ButtonBase>
    </div>
  )
}

const sButtonReactive = {
  active: {
    opacity: 1,
  },

  inactive: {
    color: colors.white,
    opacity: 0.3,
    filter: 'grayscale(1)',
    '&:hover': {
      opacity: 0.6,
      [s.hover]: {
        transform: 'translateY(0px)',
        filter: 'grayscale(1)',
      },
    },
  },
}
