import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Backdrop from '@material-ui/core/Backdrop'

import { colors, s } from '../../style'

const FormBackdrop = ({
  open,
  setOpen,
  theme,
  success,
  dataSuccess,
  dataFailure,
}) => {
  return (
    <Backdrop css={sBackdrop(theme)} open={open} onClick={() => setOpen(false)}>
      {success === null && <CircularProgress size={128} color="inherit" />}
      {success === true && (
        <div css={[sResponse]}>
          <p className="response-title">{dataSuccess.title}</p>
          <p className="response-text">{dataSuccess.text}</p>
        </div>
      )}
      {success === false && (
        <div css={[sResponse]}>
          <p className="response-title">{dataFailure.title}</p>
          <p className="response-text">{dataFailure.text}</p>
        </div>
      )}
    </Backdrop>
  )
}

const sBackdrop = (theme) => ({
  zIndex: 1010,
  backdropFilter: 'blur(16px) brightness(120%)',
  color: colors[theme].mainLighter,
})

const sResponse = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '.response-title': {
    fontSize: '3rem',
    paddingBottom: '2rem',
    color: 'white',
    fontWeight: 700,
    [s.ss]: { fontSize: '2rem' },
  },
  '.response-text': {
    fontSize: '1rem',
    color: 'white',
    padding: '0 2rem',
    textAlign: 'center',
  },
}

export default FormBackdrop
