import React, { useState } from 'react'
import { s, globals, colors, alpha, q } from '../../style'

const FormAgreement = ({
  text,
  id,
  theme,
  setFormAgreements,
  formAgreements,
  isExpanded,
  hideWhiteHidder,
}) => {
  const [expanded, setExpanded] = useState(isExpanded ? true : false)

  const toogleBoolean = formAgreements.map((e, i) =>
    i === id ? (e === true ? false : true) : e === true ? true : false
  )

  return (
    <div
      key={id}
      css={[
        sAgreement(theme),
        formAgreements[id] === true && sAgreement(theme).active,
      ]}>
      <div
        className="form_agreement_check"
        onClick={() => setFormAgreements(toogleBoolean)}
        onKeyPress={() => setFormAgreements(toogleBoolean)}
        role="button"
        tabIndex={0}>
        <div className="form_agreement_check_inside" />
      </div>
      <div
        className="form_agreement_text"
        css={[
          expanded
            ? { height: '100%' }
            : {
                height: 40,
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              },
        ]}
        onClick={() =>
          isExpanded ? setFormAgreements(toogleBoolean) : setExpanded(!expanded)
        }
        onKeyPress={() =>
          isExpanded ? setFormAgreements(toogleBoolean) : setExpanded(!expanded)
        }
        role="button"
        tabIndex={0}>
        <div
          css={[
            !isExpanded && sTextHidder(theme, hideWhiteHidder),
            { opacity: expanded ? 0 : 1 },
          ]}
        />
        {text}
      </div>
    </div>
  )
}

const FormAgreements = ({
  theme,
  formAgreements,
  setFormAgreements,
  data,
  extraCssContainer,
  isExpanded,
  hideWhiteHidder,
}) => {
  return (
    <div css={[sContainer, extraCssContainer]}>
      {/* {formAgreements.map((e, id) => (
        <span key={id}>{e ? 'true' : 'false'},</span>
      ))} */}
      {data.map(({ text }, id) => (
        <FormAgreement
          hideWhiteHidder={hideWhiteHidder}
          isExpanded={isExpanded}
          key={id}
          text={text}
          id={id}
          theme={theme}
          formAgreements={formAgreements}
          setFormAgreements={setFormAgreements}
        />
      ))}
    </div>
  )
}
const sContainer = {
  [s.sm_down]: {
    marginTop: globals.spacing.inside.mobile,
    marginBottom: globals.spacing.inside.mobile,
  },
  [s.md]: {
    marginTop: globals.spacing.inside.desktop,
    marginBottom: globals.spacing.inside.desktop,
  },
}

const sAgreement = (theme) => ({
  cursor: 'pointer',
  display: 'flex',
  padding: '1rem',

  '.form_agreement_check': {
    flexShrink: 0,
    transition: 'box-shadow 0.25s ease-out, opacity 0.25s ease-out',
    ...q.center,
    width: 20,
    height: 20,
    boxShadow: `0px 0px 0px 2px ${colors[theme].text}`,
    opacity: 0.3,
    marginRight: '1rem',
    marginTop: '0.5rem',
    borderRadius: 10,
  },
  '.form_agreement_check_inside': {
    transition: 'transform 0.25s ease-in-out, height 0.25s ease-out',
    width: 14,
    height: 14,
    borderRadius: 8,
    backgroundColor:
      theme === 'light' ? colors[theme].main : colors[theme].text,
    transform: 'scale(0)',
  },
  '.form_agreement_text': {
    transition: 'color 0.25s ease-out, opacity 0.25s ease-out',
    opacity: 0.6,
    fontSize: 13,
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    '&:hover': {
      opacity: 1,
    },
  },

  active: {
    '.form_agreement_check': {
      boxShadow:
        theme === 'light'
          ? `0px 0px 0px 2px ${colors[theme].main}`
          : `0px 0px 0px 2px ${colors[theme].text}`,
      opacity: 1,
    },
    '.form_agreement_check_inside': {
      transform: 'scale(1)',
    },
    '.form_agreement_text': {
      color: theme === 'light' ? colors[theme].main : colors[theme].text,
      opacity: 1,
    },
  },
})

const sTextHidder = (theme, hideWhiteHidder) => ({
  top: 0,
  right: 0,
  width: '100%',
  height: '100%',
  position: 'absolute',

  background: hideWhiteHidder
    ? 'transparent'
    : `linear-gradient(${colors[theme].container.concat(alpha[0])}, ${
        colors[theme].container
      })`,
})

export default FormAgreements
