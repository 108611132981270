import React from 'react'
import { TextField, Grid } from '@material-ui/core'
import { s, globals, colors, alpha } from '../../style'

const FormInputs = ({ theme, formData, setFormData, data }) => {
  const onValueChange = (event) =>
    setFormData({ ...formData, [event.target.name]: event.target.value })

  if (data)
    return (
      <div css={sSectionContainer}>
        <Grid container css={sContainer}>
          {Object.entries(data).map(([key, value], id) => (
            <Grid item xs={12} md={6} key={id} css={sInputContainer}>
              <TextField
                onChange={onValueChange}
                name={key}
                value={formData[key]}
                variant="filled"
                label={value}
                multiline={key === 'message'}
                rows={key === 'message' ? 4 : 1}
                css={sTextInput(theme)}
                fullWidth
              />
            </Grid>
          ))}
        </Grid>
      </div>
    )

  return <p>No data</p>
}

const sSectionContainer = {
  [s.sm_down]: {
    marginTop: globals.spacing.inside.mobile,
  },
  [s.md]: {
    marginTop: globals.spacing.inside.desktop / 2,
  },
}

const sContainer = {
  [s.sm_down]: {
    marginTop: globals.spacing.inside.mobile,
  },
  [s.md]: {
    marginTop: globals.spacing.inside.desktop / 2,
  },
}

const sInputContainer = {
  padding: '1rem',
}

export const sTextInput = (theme) => ({
  // backgroundColor: colors[theme].container.concat(alpha[100]),
  borderRadius: '1rem',
  label: {
    fontWeight: 700,
    textTransform: 'uppercase',
    paddingLeft: '1rem',
    color: `${colors[theme].text.concat(alpha[30])} !important`,
  },
  input: {
    color: `${colors[theme].text.concat(alpha[60])} !important`,
    paddingLeft: '1.5rem',
    paddingTop: '2rem',
    '&:focus, &:hover': {
      color: `${colors[theme].text} !important`,
    },
  },
  textarea: {
    paddingLeft: '0.75rem',
    color: `${colors[theme].text.concat(alpha[60])} !important`,
    paddingTop: '0.5rem',
    '&:focus, &:hover': {
      color: `${colors[theme].text} !important`,
    },
  },
  'div, input, textarea': {
    borderRadius: 'inherit',
  },
  '.MuiFilledInput-underline:before': {
    borderBottom: 'none',
  },
  '.MuiFilledInput-underline:after': {
    width: 'calc(100% - 2rem)',
    left: '1rem',
    borderColor: `${colors[theme].main} !important`,
  },
  '.MuiFilledInput-underline:before .Mui-focuesd': {
    borderBottom: 'none',
  },
  '.MuiFormLabel-root.Mui-focused': {
    color: `${colors[theme].main} !important`,
  },
})

export default FormInputs
